import "./App.css";
import lucasbargaLogo from "./lucasbarga-logo.png";

function App() {
  return (
    <div className="App">
      <img
        src={lucasbargaLogo}
        alt="lucasbargaLogo"
        className="lucasbarga-logo"
      />
    </div>
  );
}

export default App;
